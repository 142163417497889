<template>
  <div>
    <navs></navs>
    <consult></consult>
    <img
      @click="tzviewwap"
      :src="img[1].picture"
      alt=""
      class="header_img"
      v-if="img.length > 0"
    />
    <div class="tablist">
      <div class="item" @click="tab(1, '职业技能等级')">
        <img src="../assets/item2_icon1.png" alt="" />
        <div>职业技能 <br />等级</div>
      </div>
      <div class="item" @click="tab(2, '浦东新区自主培训')">
        <img src="../assets/item2_icon2.png" alt="" />
        <div>浦东新区<br />自主培训</div>
      </div>
      <div class="item" @click="tab(3, '企业补贴培训')">
        <img src="../assets/item2_icon3.png" alt="" />
        <div>企业补贴<br />培训</div>
      </div>
      <!-- <div class="item" @click="tab(4, '高技企业 补贴培训')">
        <img src="../assets/item2_icon4.png" alt="" />
        <div>高技企业<br />补贴培训</div>
      </div>
      <div class="item" @click="tab(5, '企业疫情 在线培训')">
        <img src="../assets/item2_icon5.png" alt="" />
        <div>企业疫情<br />在线培训</div>
      </div>
      <div class="item" @click="tab(6, '教育附加 补贴培训')">
        <img src="../assets/item2_icon6.png" alt="" />
        <div>教育附加<br />补贴培训</div>
      </div> -->
      <div class="dian">
        <div :class="type == 1 ? 'dian1 c' : 'dian1'"></div>
        <div :class="type == 2 ? 'dian1 c' : 'dian1'"></div>
        <div :class="type == 3 ? 'dian1 c' : 'dian1'"></div>
      </div>
    </div>
    <router-link to="/rights" class="quanyi">
      <img class="imgs" src="../assets/dayi.png" alt="" />
      <div class="box">
        <div class="left">
          <div class="title">会员权益</div>
          <div class="brief">5大会员权益，平台资源为你保驾护航！</div>
        </div>
        <div class="right">
          <img src="../assets/youjt.png" alt="" />
        </div>
      </div>
    </router-link>
    <div class="conter">
      <div class="title">{{ title }}</div>
    </div>
    <div class="picker" v-show="type == 1 || type == 2" @click="show = true">
      请选择课程等级：{{ grade }}
    </div>
    <div class="list1">
      <router-link
        v-for="(item, index) in list"
        :key="index"
        :to="`/traindetails?id=${item.id}`"
        class="item1"
      >
        <div class="header1">
          <img src="../assets/to.png" /><span
            style="
              font-size: 0.26rem;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #2978ee;
            "
            >{{ item.name }}</span
          >
        </div>
        <div class="mini1">
          <img
            style="
              width: 1.96rem;
              height: 1.39rem;
              margin-right: 0.31rem;
              margin-left: 0.15rem;
            "
            :src="item.picture || src"
          />

          <div
            style="
              color: #808080;
              width: 3.95rem;
              height: 1.3rem;
              line-height: 0.45rem;
              font-size: 0.24rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            "
          >
            {{ item.synopsis || "暂无" }}
          </div>
        </div>
        <div class="footer1">
          {{ item.grade || "暂无等级" }} . {{ item.interested }}人 感兴趣
        </div>
        <img class="ff" src="../assets/zx.png" />
      </router-link>
    </div>
    <div style="height: 1.44rem"></div>
    <tbrs></tbrs>
    <van-popup v-model="show" position="bottom"
      ><van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
    /></van-popup>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      columns: ["五级", "四级", "三级", "二级", "一级"],
      show: false,
      grade: "",
      type: 1,
      list: [],
      title: "职业资格培训",
      img: [],
      src: require("../assets/mr2.png"),
    };
  },
  computed: {
    ...mapState({
      userinfo: (state) => state.userinfo,
      invitationinif: (state) => state.invitationinif,
    }),
  },
  methods: {
    ...mapActions(["getlesson", "getbanner"]),
    onConfirm(value, index) {
      this.grade = value;
      this.show = !this.show;
      this.getlesson({
        grade: this.grade,
        page: 1,
        pageSize: 100,
        type: this.type,
      }).then((res) => {
        if (res.SuccessCode == 200) {
          if (res.ResponseBody.data.length == 0) {
            this.$toast.fail("暂无内容");
          }
          this.list = res.ResponseBody.data;
        } else {
        }
      });
    },
    tzviewwap() {
      let url = this.img[1].url.split(",");
      if (url[1] == 1) {
        this.$router.push({
          path: "/policydetails",
          query: {
            id: url[0],
          },
        });
      }
      if (url[1] == 2) {
        this.$router.push({
          path: "/traindetails",
          query: {
            id: url[0],
          },
        });
      }
      if (url[1] == 3) {
        this.$router.push({
          path: "/traindetails",
          query: {
            id: url[0],
          },
        });
      }
      if (url[1] == 4) {
        this.$router.push({
          path: "/instrumentdetails",
          query: {
            id: url[0],
          },
        });
      }
    },
    onCancel() {
      this.show = !this.show;
    },
    tab(val, title) {
      this.grade = "";
      this.title = title;
      this.type = val;
      if (val == 1) {
        this.columns = ["五级", "四级", "三级", "二级", "一级"];
      } else if (val == 2) {
        this.columns = ["五级", "四级", "三级"];
      }
      this.getlesson({
        page: 1,
        pageSize: 100,
        type: val,
        grade: this.grade,
      }).then((res) => {
        if (res.SuccessCode == 200) {
          if (res.ResponseBody.data.length == 0) {
            this.$toast.fail("暂无内容");
          }
          this.list = res.ResponseBody.data;
        } else {
        }
      });
    },
  },
  mounted() {
    if (this.userinfo == "") {
      this.$dialog
        .confirm({
          title: "",
          message: "为了更好的体验请登录",
        })
        .then(() => {
          this.$router.push({
            path: "/user",
          });
        })
        .catch(() => {});
    }
    this.getlesson({
      grade: this.grade,
      page: 1,
      pageSize: 100,
      type: this.type,
    }).then((res) => {
      if (res.SuccessCode == 200) {
        if (res.ResponseBody.data.length == 0) {
          this.$toast.fail("暂无内容");
        }
        this.list = res.ResponseBody.data;
      } else {
      }
    });
    this.getbanner({}).then((res) => {
      if (res.SuccessCode == 200) {
        this.img = res.ResponseBody;
      }
    });
  },
};
</script>

<style lang='scss' scoped>
.header_img {
  margin-left: 0.2rem;
  width: 7.1rem;
  height: 1.96rem;
}
.tablist {
  position: relative;
  padding-bottom: 0.4rem;
  margin-top: 0.15rem;
  display: flex;
  flex-wrap: wrap;
  width: 7.04rem;
  /* height: 464px; */
  background: #ffffff;
  box-shadow: 0px 0.05rem 0.14rem 0.03rem rgba(148, 148, 148, 0.23);
  border-radius: 0.2rem;
  margin-left: 0.24rem;
  .dian {
    position: absolute;
    top: 2rem;
    left: 3rem;
    display: flex;
    .dian1 {
      width: 0.16rem;
      height: 0.16rem;
      border-radius: 0.08rem;
      margin-right: 0.13rem;
      background: #b0ccff;
      transition: width 0.5s;
    }
    .c {
      width: 0.6rem;
      background-color: #065eff;
    }
  }
  .item {
    text-align: center;
    font-size: 0.26rem;
    width: 33.3%;
    margin: 0.2rem 0;
    img {
      width: 0.9rem;
      height: 0.9rem;
    }
  }
}
.conter {
  padding: 0 0.24rem;
  .title {
    margin-top: 0.37rem;
    font-size: 0.3rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;

    color: #333333;
    &::before {
      margin-right: 0.15rem;
      transform: translateY(0.05rem);
      display: inline-block;
      content: "";
      width: 0.07rem;
      height: 0.28rem;
      background: #458aec;
    }
  }
}
.quanyi {
  margin-top: 0.36rem;
  width: 7.04rem;

  margin-left: 0.22rem;
  display: flex;
  padding: 0.34rem;
  box-sizing: border-box;
  box-shadow: 0px 0.05rem 0.14rem 0.03rem rgba(148, 148, 148, 0.23);
  border-radius: 0.14rem;
  .imgs {
    margin-right: 0.1rem;
    width: 0.8rem;
    height: 0.79rem;
  }
  .box {
    display: flex;
    align-items: center;
    height: 0.8rem;
    .title {
      font-size: 0.3rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .brief {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6c6c6c;
    }
    .right {
      img {
        width: 0.3rem;
        margin-left: 0.3rem;
        vertical-align: middle;
      }
    }
  }
}
.picker {
  box-sizing: border-box;
  padding-left: 0.24rem;
  margin-left: 0.24rem;
  display: flex;
  margin-top: 0.15rem;
  width: 7.02rem;
  height: 0.88rem;
  background: #ffffff;
  box-shadow: 0px 0.05rem 0.14rem 0.03rem rgba(148, 148, 148, 0.23);
  border-radius: 0.09rem;
  font-size: 0.3rem;
  line-height: 0.88rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #808080;
}
.list1 {
  padding: 0 0.2rem;
  box-sizing: border-box;
  .item1 {
    margin: 0.2rem 0;
    display: block;
    overflow: hidden;
    position: relative;
    width: 7.08rem;
    background: #ffffff;
    box-shadow: 0px 0.05rem 0.14rem 0.03rem rgba(148, 148, 148, 0.23);
    border-radius: 0.09rem;
    margin-bottom: 0.4rem;

    .header1 {
      display: flex;
      align-items: center;
      height: 0.4rem;
      margin-top: 0.15rem;
      img {
        vertical-align: middle;
        margin-right: 0.15rem;
        width: 0.55rem;
        height: 0.42rem;
      }
    }
    .mini1 {
      margin-top: 0.35rem;
      display: flex;
    }
    .footer1 {
      margin-top: 0.2rem;
      margin-left: 0.31rem;
      padding-bottom: 0.2rem;
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ababab;
    }
    .ff {
      width: 0.7rem;
      height: 0.64rem;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
</style>